import {
  Typography,
  Box,
  Paper,
  Switch,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import { useAsyncState } from 'app/hooks';

import type { state } from 'app';
import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { deleteDoc, doc, serverTimestamp, getDoc, getFirestore, setDoc } from 'firebase/firestore';

export function UserProfileForm({ user, auth }: { user: state.User; auth: User | null; onClose: () => void }) {
  const [userIsSubscribed, setUserIsSubscribed] = useState<boolean | undefined>(undefined);
  const [subscriptionState, subscriptionSuccess, subscriptionFailure, subscriptionWaiting] = useAsyncState();

  useEffect(() => {
    if (subscriptionState.status === undefined && user.uid) {
      subscriptionWaiting();
      getUserIsSubscribed(user.uid).then((subscribed) => {
        subscriptionSuccess();
        setUserIsSubscribed(subscribed);
      }, subscriptionFailure);
    }
  }, [user, subscriptionState, subscriptionSuccess, subscriptionFailure, subscriptionWaiting]);

  const handleSubscriptionSwitchChange = (_: unknown, checked: boolean) => {
    if (user.uid) {
      subscriptionWaiting();
      updateUserIsSubscribed(user.uid, checked).then(() => {
        setUserIsSubscribed(checked);
        subscriptionSuccess();
      }, subscriptionFailure);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Paper sx={{ p: 2, my: 2 }}>
        <Typography variant="h6" color="secondary">
          Manage Notifications
        </Typography>
        <Typography sx={{ whiteSpace: 'break-anywhere', my: 2 }}>
          <strong>Account Email</strong>: {auth?.email}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          You'll get email alerts when you have a new module or survey available. Parents who have completed PiP in the
          past have found these reminders helpful. If you prefer not to receive emails, or have unsubscribed and want to
          re-subscribe, you can update your preference below.
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                onChange={handleSubscriptionSwitchChange}
                checked={userIsSubscribed ?? false}
                disabled={subscriptionState.status !== 'fulfilled'}
              />
            }
            label="Subscribed"
          />
        </Box>
        {subscriptionState.status === 'pending' ? (
          <Box sx={{ display: 'flex', m: 1, justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : null}
      </Paper>
    </Box>
  );
}

const getUserIsSubscribed = async (userId: string) => {
  const firestore = getFirestore();
  return !(await getDoc(doc(firestore, `emailUnsubscribes/${userId}`))).exists();
};

const updateUserIsSubscribed = async (userId: string, subscribed: boolean) => {
  const firestore = getFirestore();
  const subscribeDoc = doc(firestore, `emailUnsubscribes/${userId}`);
  if (subscribed) {
    deleteDoc(subscribeDoc);
    return;
  }
  await setDoc(subscribeDoc, { unsubscribedAt: serverTimestamp() });
};
