import { Box, Grid, Typography, TextField, Stack, Paper, Alert } from '@mui/material';
import { WaitButton } from 'components';
import { Form, mustMatch, passwordPolicy } from 'components/form';
import { useAsyncState } from 'app/hooks';
import { useAppDispatch, changePassword } from 'app/store';
import { Link } from 'gatsby';

export type ChangePasswordData = {
  password: string;
  verify: string;
};

const validators = {
  password: passwordPolicy(),
  verify: mustMatch('password'),
};

export function ChangePasswordForm() {
  const dispatch = useAppDispatch();
  const [asyncStatus, success, failure, waiting] = useAsyncState();

  const handleSubmit = (data: ChangePasswordData) => {
    dispatch(changePassword(data.password))
      .unwrap()
      .then(() => {
        success();
      })
      .catch(failure);
    waiting();
  };

  return (
    <Paper sx={{ p: 2, my: 2 }}>
      <Typography variant="h6" color="secondary">
        Change password
      </Typography>

      <Typography variant="subtitle1" color="textSecondary">
        To change your password, please type in your new password and verify the password.
      </Typography>

      {asyncStatus.status === 'failure' && <Alert severity="error">Failed to change your password!</Alert>}

      {asyncStatus.status === 'fulfilled' ? (
        <Alert severity="success">Your password has been successfully changed!</Alert>
      ) : (
        <Form<ChangePasswordData>
          onSubmit={handleSubmit}
          validators={validators}
          initialData={{ password: '', verify: '' }}>
          {(state, updateField) => (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="password"
                    type="password"
                    variant="filled"
                    label="New password"
                    value={state.data.password}
                    onChange={(e) => updateField('password', e.target.value)}
                    error={Boolean(state.errors.password)}
                    helperText={state.errors.password}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="password"
                    name="verify"
                    variant="filled"
                    label="Verify your new password"
                    value={state.data.verify}
                    onChange={(e) => updateField('verify', e.target.value)}
                    error={Boolean(state.errors.verify)}
                    helperText={state.errors.verify}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>

              <Stack sx={{ mt: 2 }} alignItems="center">
                <WaitButton type="submit" color="primary" variant="outlined" wait={asyncStatus.status === 'pending'}>
                  Submit
                </WaitButton>
              </Stack>
            </Box>
          )}
        </Form>
      )}
    </Paper>
  );
}
