import { navigate } from 'gatsby-link';
import { Container } from '@mui/material';
import { useAppSelector } from 'app/store';
import { ensureAuth } from 'app/helpers';
import { SitePage } from 'app/layout';
import { UserProfileForm } from 'app/profile/settings';

function EditProfilePage() {
  const { user } = useAppSelector((state) => state.profile.data!);
  const { userInfo } = useAppSelector((state) => state.auth);

  const handleClose = () => {
    navigate('/dashboard');
  };

  return (
    <SitePage>
      <Container sx={{ pt: 2 }}>
        <UserProfileForm user={user} auth={userInfo} onClose={handleClose} />
      </Container>
    </SitePage>
  );
}

export default ensureAuth(EditProfilePage);
